





























































































import Vue from 'vue';
import Component from 'vue-class-component';
import { UserSubscription } from '../../models';
import Subscription from '../../models/Subscription';
import OverlayLoader from '../shared/OverlayLoader.vue';
import ConfirmationDialog from '../shared/ConfirmationDialog.vue';
import UserService from '../../services/UserService';
import { PermissionLevel } from '../../enums';

@Component({
  components: {
    OverlayLoader,
    ConfirmationDialog,
  },
})
export default class AlertList extends Vue {
  isLoading: boolean = false;
  selectedUserSubscriptionId: string = '';
  showDialog: boolean = false;
  subscriptionTypes: string[] = ['SMS', 'Email'];
  showAlert: boolean = false;
  alertMessage: string = '';
  timeout: number = 2000;

  get userSubscriptions(): UserSubscription[] {
    return this.$store.state.userSubscriptions.sort((a: UserSubscription, b: UserSubscription) => {
      return a.name.toLowerCase().localeCompare(b.name);
    });
  }

  get canEdit(): boolean {
    return UserService.hasPermission(PermissionLevel.ReadWrite);
  }

  mounted() {
    this.isLoading = true;
    this.$store.dispatch('loadUserSubscriptions')
      .then(() => {
        this.isLoading = false;
      });
  }

  tryGet(userSubscription: UserSubscription, type: string): Subscription | string {
    const sub = userSubscription.subscriptions
      .find(f => f.type.toLowerCase() === type.toLowerCase());
    if (sub) {
      return sub;
    }
    return 'Not set';
  }

  getEnvironments(userSubscription: UserSubscription, type: string): Subscription | string {
    const sub = userSubscription.subscriptions
      .find(f => f.type.toLowerCase() === type.toLowerCase());
    if (sub) {
      return sub.environments.toString();
    }
    return '-';
  }

  updateSubscription(userSubscription: UserSubscription): void {
    this.isLoading = true;
    this.$store.dispatch('updateUserSubscription', userSubscription)
      .then((_) => {
        this.isLoading = false;
      });
  }

  editUserSubscription(userSubscription: UserSubscription): void {
    this.$router.push(`../alerts/${userSubscription.id}/edit`);
  }

  confirmDelete(userSubscription: UserSubscription) {
    this.selectedUserSubscriptionId = userSubscription.id;
    this.showDialog = true;
  }

  deleteUserSubscription(): void {
    this.isLoading = true;
    this.$store.dispatch('deleteUserSubscription', this.selectedUserSubscriptionId)
      .then(() => {
        this.isLoading = false;
      });
    this.selectedUserSubscriptionId = '';
    this.showDialog = false;
  }

  cancelDelete(): void {
    this.selectedUserSubscriptionId = '';
    this.showDialog = false;
  }

  setAlert(message: string) {
    this.alertMessage = message;
    this.showAlert = true;
  }

  testAlert(type: string, value: string): void {
    this.isLoading = true;
    this.$store.dispatch('testAlert', { type, value })
      .then((result: string) => {
        if (result) {
          return this.setAlert(result);
        }
        return this.setAlert(`Unable to send ${type} test alert.`);
      })
      .finally(() => { this.isLoading = false; });
  }
}
