















import Vue from 'vue';
import Component from 'vue-class-component';
import { ComponentStatus } from '@/enums';
import ThemeColor from '@/assets/theme-color';
import { SyncService } from '../../services';
import { ComponentMetric } from '../../models';
import ComponentTiles from '../shared/ComponentTiles.vue';
import OverlayLoader from '../shared/OverlayLoader.vue';

@Component({
  components: {
    ComponentTiles,
    OverlayLoader,
  },
})
export default class AdminContent extends Vue {
  isLoading: boolean = false;
  message: string = '';

  mounted() {
    this.isLoading = true;
    this.$store.dispatch('loadStatusServices')
      .then(() => {
        this.isLoading = false;
      });
  }

  syncEnvironments(): void {
    this.isLoading = true;
    SyncService.syncEnvironments()
      .then((success: boolean) => {
        this.isLoading = false;
        if (success) {
          this.message = 'Success, synced environments.';
        } else {
          this.message = 'Failure, syncing is only enabled in the prod environment';
        }
      });
  }

  get metrics(): ComponentMetric[] {
    return this.$store.state.componentAdminMetrics;
  }
}
