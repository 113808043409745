







import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import AlertList from '@/components/alert/AlertList.vue';
import AlertHeader from '@/components/alert/AlertHeader.vue';

@Component({
  components: {
    AlertList,
    AlertHeader,
  },
})
export default class AlertsView extends Vue {
}
