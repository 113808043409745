




import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import AlertForm from '@/components/alert/AlertForm.vue';

@Component({
  components: {
    AlertForm,
  },
})
export default class AddAlertView extends Vue {

}
