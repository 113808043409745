





















































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { debounce } from 'lodash';
import OverlayLoader from '../shared/OverlayLoader.vue';
import { UserSubscription, ActiveDirectoryUser, ComponentModel } from '../../models';
import Subscription from '../../models/Subscription';
import UserService from '../../services/UserService';
import { ENVIRONMENT_TYPES } from '../../helpers/Constants';
import regex from '../../helpers/Regex';

@Component({
  components: {
    OverlayLoader,
  },
})
export default class AlertForm extends Vue {
  readonly environments = ENVIRONMENT_TYPES;
  isLoading: boolean = false;
  initialUserSubscription: string = '';
  userSubscription: UserSubscription = new UserSubscription();
  emailRegex: RegExp = new RegExp(regex.email);
  phoneRegex: RegExp = new RegExp(regex.phoneNumber);
  selectedComponents: string[] = [];

  // autocomplete
  isSearching: boolean = false;
  query: string = '';
  users: ActiveDirectoryUser[] = [];
  selectedUser: ActiveDirectoryUser = null!;
  search: Function;

  // form values
  email: string = '';
  phoneNumber: string = '';
  smsSelectedEnvironments: string[] = [];
  emailSelectedEnvironments: string[] = [];

  @Watch('query')
  onSearchChanged(input: string, oldInput: string) {
    if (!input) {
      return;
    }
    this.isSearching = true;
    this.search(input);
  }

  data() {
    return {
      rules: {
        required: (v: string) => !!v || 'Required.',
        email: (v: string) => this.emailRegex.test(v) || 'Invalid email.',
        phoneNumber: (v: string) => this.phoneRegex.test(v) || 'Invalid Phone Number.',
        requiredUser: (v: string) => (this.selectedUser && this.selectedUser.id ? true : 'Invalid user.'),
      },
    };
  }

  get components(): ComponentModel[] {
    return this.$store.state.components.filter((x: ComponentModel) => x.type === 'API' || x.type === 'WindowsService')
      .sort((a: ComponentModel, b: ComponentModel) => {
        return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
      });
  }

  mounted(): void {
    this.isLoading = true;
    Promise.all([
      this.$store.dispatch('loadUserSubscriptions'),
      this.$store.dispatch('loadComponents'),
    ]).then(() => {
      const userSubscription: UserSubscription = this.$store.getters.userSubscriptionById(
        this.$route?.params?.id,
      );

      // setup existing subscription if there is one
      if (userSubscription) {
        this.userSubscription = userSubscription;
        this.selectedUser = {
          displayName: userSubscription.name,
          id: userSubscription.adId,
          mail: `${userSubscription.username}@universalweather.net`,
        };
        this.users.push(this.selectedUser);
        this.email = userSubscription.email;
        this.phoneNumber = userSubscription.phoneNumber;
        this.selectedComponents = userSubscription.components;
        this.userSubscription.subscriptions.map((sub) => {
          if (sub.type === 'SMS') {
            this.smsSelectedEnvironments = sub.environments;
          } else {
            this.emailSelectedEnvironments = sub.environments;
          }
          return '';
        });
        this.initialUserSubscription = JSON.stringify({
          name: userSubscription.name,
          email: userSubscription.email,
          phoneNumber: userSubscription.phoneNumber,
          smsEnvironments: this.smsSelectedEnvironments,
          emailEnvironments: this.emailSelectedEnvironments,
          selectedComponents: this.selectedComponents,
        });
      }

      this.isLoading = false;
    });

    // add debounce to watcher
    this.search = debounce((query: string) => {
      UserService.searchUsers(query).then((users: any[]) => {
        this.users = users;
        this.isSearching = false;
      });
    }, 300);
  }

  isAllComponentsSelected() {
    return this.selectedComponents.length === this.components.length;
  }

  isSomeComponentsSelected() {
    return this.selectedComponents.length > 0 && !this.isAllComponentsSelected;
  }

  getIcon(): string {
    if (this.isAllComponentsSelected()) return 'mdi-close-box';
    if (this.isSomeComponentsSelected()) return 'mdi-minus-box';
    return 'mdi-checkbox-blank-outline';
  }

  toggleSelectAll(): void {
    this.$nextTick(() => {
      if (this.isAllComponentsSelected()) {
        this.selectedComponents = [];
      } else {
        this.selectedComponents = this.components.map(x => x.id);
      }
    });
  }

  onSubmit(): void {
    const form: any = this.$refs.inputForm;
    if (!form.validate()) {
      return;
    }

    // abort if the values are the same
    if (
      this.initialUserSubscription
      === JSON.stringify({
        name: this.selectedUser.displayName,
        email: this.email,
        phoneNumber: this.phoneNumber,
        smsEnvironments: this.smsSelectedEnvironments,
        emailEnvironments: this.emailSelectedEnvironments,
        selectedComponents: this.selectedComponents,
      })
    ) {
      return;
    }

    // update or set the user subscription todo: refactor
    this.userSubscription.name = this.selectedUser.displayName;
    this.userSubscription.adId = this.selectedUser.id;
    this.userSubscription.username = this.selectedUser.mail.substring(
      0,
      this.selectedUser.mail.lastIndexOf('@'),
    );
    this.userSubscription.components = this.selectedComponents;

    const emailSub = this.userSubscription.subscriptions.find(
      (f: Subscription) => f.type.toLowerCase() === 'email',
    );
    if (emailSub) {
      // edit or remove
      if (this.email) {
        emailSub.email = this.email;
        emailSub.environments = this.emailSelectedEnvironments;
      } else {
        this.userSubscription.removeSubscription('email');
      }
    } else if (this.email) {
      // add
      this.userSubscription.subscriptions.push(
        new Subscription({
          type: 'email',
          email: this.email,
          environments: this.emailSelectedEnvironments,
        }),
      );
    }
    const smsSub = this.userSubscription.subscriptions.find(
      (f: Subscription) => f.type.toLowerCase() === 'sms',
    );
    if (smsSub) {
      if (this.phoneNumber) {
        smsSub.phoneNumber = this.phoneNumber;
        smsSub.environments = this.smsSelectedEnvironments;
      } else {
        this.userSubscription.removeSubscription('sms');
      }
    } else if (this.phoneNumber) {
      this.userSubscription.subscriptions.push(new Subscription({
        type: 'sms',
        phoneNumber: this.phoneNumber,
        environments: this.smsSelectedEnvironments,
      }));
    }

    this.isLoading = true;
    const mode = this.userSubscription.id
      ? { action: 'updateUserSubscription', route: '../../alerts' }
      : { action: 'saveUserSubscription', route: '../alerts' };

    this.$store.dispatch(mode.action, this.userSubscription)
      .then((result: boolean) => {
        this.isLoading = false;
        if (result) {
          this.$router.push(mode.route);
        }
      });
  }
}
