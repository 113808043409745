







import Vue from 'vue';
import Component from 'vue-class-component';
import AdminHeader from '../components/admin/AdminHeader.vue';
import AdminContent from '../components/admin/AdminContent.vue';

@Component({
  components: {
    AdminHeader,
    AdminContent,
  },
})
export default class AdminView extends Vue {
  mounted() {
  }
}
