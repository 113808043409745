





































import Vue from 'vue';
import Component from 'vue-class-component';
import { StatusSettings } from '../../models';
import { ENVIRONMENT_TYPES } from '../../helpers/Constants';

@Component
export default class AlertHeader extends Vue {
  environments = ENVIRONMENT_TYPES.map((env) => {
    return { label: env, enabled: false };
  });
  isLoading: boolean = false;

  mounted(): void {
    this.$store.dispatch('loadStatusSettings')
      .then(() => {
        const {
          alertsEnabledTst, alertsEnabledDev,
          alertsEnabledPrd, alertsEnabledStg,
        } = this.$store.state.statusSettings;
        this.environments = ENVIRONMENT_TYPES.map((env) => {
          switch (env) {
            case 'TST':
              return { label: env, enabled: alertsEnabledTst };
            case 'PRD':
              return { label: env, enabled: alertsEnabledPrd };
            case 'STG':
              return { label: env, enabled: alertsEnabledStg };
            case 'DEV':
              return { label: env, enabled: alertsEnabledDev };
            default:
              break;
          }
          return { label: env, enabled: false };
        });
      });
  }

  toggleAlert(index: number, enabled: boolean) {
    this.environments[index].enabled = enabled;
    this.updateAlertsSetting(index, enabled);
  }

  updateAlertsSetting(index: number, enabled: boolean): void {
    this.isLoading = true;

    const environment = this.environments[index].label;
    this.$store.dispatch('updateAlertsSettings', { enabled, environment })
      .then(() => {
        this.isLoading = false;
      });
  }
}
